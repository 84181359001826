<template>
  <div class="about">
    <h1>This is an about page</h1>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'

export default {
  name: 'Home',
  data(){
    return{
    }
  },
  mounted(){

// axios.post('http://localhost/smp-afbs/public/api2/notif/permohonan2',{} )
//                     .then (result => {
//                       console.log(result.data);
//                     })


  },
  methods:{

  },
  computed:{
    maxHeight(){
      return this.$q.screen.height
    }
  }
}
</script>
